import { default as _91_46_46_46slug_93XDjAndoEVYMeta } from "/home/runner/work/grove-web/grove-web/pages/[...slug].vue?macro=true";
import { default as _91slug_93LO4mCRzse4Meta } from "/home/runner/work/grove-web/grove-web/pages/blog/[slug].vue?macro=true";
import { default as blogFJuEywsORUMeta } from "/home/runner/work/grove-web/grove-web/pages/blog.vue?macro=true";
import { default as careers37EUfxjFQaMeta } from "/home/runner/work/grove-web/grove-web/pages/careers.vue?macro=true";
import { default as _91slug_93yZoDBjH8clMeta } from "/home/runner/work/grove-web/grove-web/pages/g/[slug].vue?macro=true";
import { default as impact6RebaI8OiJMeta } from "/home/runner/work/grove-web/grove-web/pages/impact.vue?macro=true";
import { default as indexSRAKe8nobwMeta } from "/home/runner/work/grove-web/grove-web/pages/index.vue?macro=true";
import { default as policiesgFtLBUNSzfMeta } from "/home/runner/work/grove-web/grove-web/pages/policies.vue?macro=true";
import { default as privacyG5tN4kYAAyMeta } from "/home/runner/work/grove-web/grove-web/pages/privacy.vue?macro=true";
import { default as referral_45termsOOxBPsQyfZMeta } from "/home/runner/work/grove-web/grove-web/pages/referral-terms.vue?macro=true";
import { default as termsfc5baMVZXXMeta } from "/home/runner/work/grove-web/grove-web/pages/terms.vue?macro=true";
import { default as _91slug_93pElYesHTTEMeta } from "~/pages/g/[slug].vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93XDjAndoEVYMeta?.name ?? "slug",
    path: _91_46_46_46slug_93XDjAndoEVYMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93XDjAndoEVYMeta || {},
    alias: _91_46_46_46slug_93XDjAndoEVYMeta?.alias || [],
    redirect: _91_46_46_46slug_93XDjAndoEVYMeta?.redirect,
    component: () => import("/home/runner/work/grove-web/grove-web/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: blogFJuEywsORUMeta?.name ?? "blog",
    path: blogFJuEywsORUMeta?.path ?? "/blog",
    meta: blogFJuEywsORUMeta || {},
    alias: blogFJuEywsORUMeta?.alias || [],
    redirect: blogFJuEywsORUMeta?.redirect,
    component: () => import("/home/runner/work/grove-web/grove-web/pages/blog.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93LO4mCRzse4Meta?.name ?? "blog-slug",
    path: _91slug_93LO4mCRzse4Meta?.path ?? ":slug()",
    meta: _91slug_93LO4mCRzse4Meta || {},
    alias: _91slug_93LO4mCRzse4Meta?.alias || [],
    redirect: _91slug_93LO4mCRzse4Meta?.redirect,
    component: () => import("/home/runner/work/grove-web/grove-web/pages/blog/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: careers37EUfxjFQaMeta?.name ?? "careers",
    path: careers37EUfxjFQaMeta?.path ?? "/careers",
    meta: careers37EUfxjFQaMeta || {},
    alias: careers37EUfxjFQaMeta?.alias || [],
    redirect: careers37EUfxjFQaMeta?.redirect,
    component: () => import("/home/runner/work/grove-web/grove-web/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yZoDBjH8clMeta?.name ?? "g-slug",
    path: _91slug_93yZoDBjH8clMeta?.path ?? "/g/:slug()",
    meta: _91slug_93yZoDBjH8clMeta || {},
    alias: _91slug_93yZoDBjH8clMeta?.alias || [],
    redirect: _91slug_93yZoDBjH8clMeta?.redirect,
    component: () => import("/home/runner/work/grove-web/grove-web/pages/g/[slug].vue").then(m => m.default || m)
  },
  {
    name: impact6RebaI8OiJMeta?.name ?? "impact",
    path: impact6RebaI8OiJMeta?.path ?? "/impact",
    meta: impact6RebaI8OiJMeta || {},
    alias: impact6RebaI8OiJMeta?.alias || [],
    redirect: impact6RebaI8OiJMeta?.redirect,
    component: () => import("/home/runner/work/grove-web/grove-web/pages/impact.vue").then(m => m.default || m)
  },
  {
    name: indexSRAKe8nobwMeta?.name ?? "index",
    path: indexSRAKe8nobwMeta?.path ?? "/",
    meta: indexSRAKe8nobwMeta || {},
    alias: indexSRAKe8nobwMeta?.alias || [],
    redirect: indexSRAKe8nobwMeta?.redirect,
    component: () => import("/home/runner/work/grove-web/grove-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: policiesgFtLBUNSzfMeta?.name ?? "policies",
    path: policiesgFtLBUNSzfMeta?.path ?? "/policies",
    meta: policiesgFtLBUNSzfMeta || {},
    alias: policiesgFtLBUNSzfMeta?.alias || [],
    redirect: policiesgFtLBUNSzfMeta?.redirect,
    component: () => import("/home/runner/work/grove-web/grove-web/pages/policies.vue").then(m => m.default || m)
  },
  {
    name: privacyG5tN4kYAAyMeta?.name ?? "privacy",
    path: privacyG5tN4kYAAyMeta?.path ?? "/privacy",
    meta: privacyG5tN4kYAAyMeta || {},
    alias: privacyG5tN4kYAAyMeta?.alias || [],
    redirect: privacyG5tN4kYAAyMeta?.redirect,
    component: () => import("/home/runner/work/grove-web/grove-web/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: referral_45termsOOxBPsQyfZMeta?.name ?? "referral-terms",
    path: referral_45termsOOxBPsQyfZMeta?.path ?? "/referral-terms",
    meta: referral_45termsOOxBPsQyfZMeta || {},
    alias: referral_45termsOOxBPsQyfZMeta?.alias || [],
    redirect: referral_45termsOOxBPsQyfZMeta?.redirect,
    component: () => import("/home/runner/work/grove-web/grove-web/pages/referral-terms.vue").then(m => m.default || m)
  },
  {
    name: termsfc5baMVZXXMeta?.name ?? "terms",
    path: termsfc5baMVZXXMeta?.path ?? "/terms",
    meta: termsfc5baMVZXXMeta || {},
    alias: termsfc5baMVZXXMeta?.alias || [],
    redirect: termsfc5baMVZXXMeta?.redirect,
    component: () => import("/home/runner/work/grove-web/grove-web/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pElYesHTTEMeta?.name ?? undefined,
    path: _91slug_93pElYesHTTEMeta?.path ?? "/beyondplastic",
    meta: _91slug_93pElYesHTTEMeta || {},
    alias: _91slug_93pElYesHTTEMeta?.alias || [],
    redirect: _91slug_93pElYesHTTEMeta?.redirect,
    component: () => import("~/pages/g/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pElYesHTTEMeta?.name ?? undefined,
    path: _91slug_93pElYesHTTEMeta?.path ?? "/how-grove-works",
    meta: _91slug_93pElYesHTTEMeta || {},
    alias: _91slug_93pElYesHTTEMeta?.alias || [],
    redirect: _91slug_93pElYesHTTEMeta?.redirect,
    component: () => import("~/pages/g/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pElYesHTTEMeta?.name ?? undefined,
    path: _91slug_93pElYesHTTEMeta?.path ?? "/paper",
    meta: _91slug_93pElYesHTTEMeta || {},
    alias: _91slug_93pElYesHTTEMeta?.alias || [],
    redirect: _91slug_93pElYesHTTEMeta?.redirect,
    component: () => import("~/pages/g/[slug].vue").then(m => m.default || m)
  }
]