import { ROUTES, type RouteWithCopy, type Route } from '~/constants/routes'

// Any change to navigation should be made in the grove SPA repo as well

export const FOOTER_NAVIGATION: {
  shop: RouteWithCopy[]
  support: RouteWithCopy[]
  grove: RouteWithCopy[]
  social: RouteWithCopy[]
  policy: Route[]
} = {
  shop: [
    {
      text: 'Health & Wellness',
      to: '/catalog/?category=health-wellness',
    },
    {
      text: 'Cleaning Essentials',
      to: '/catalog/?category=cleaning-essentials',
    },
    {
      text: 'Home & Pantry',
      to: '/catalog/?category=home-pantry',
    },
    {
      text: 'Personal Care',
      to: '/catalog/?category=personal-care',
    },
    {
      text: 'Pet',
      to: '/catalog/?category=pet',
    },
    {
      text: 'Deals',
      to: '/catalog/?category=deals',
    },
  ],
  support: [
    ROUTES.frequentlyAsked,
    ROUTES.careers,
    {
      text: 'Partner Program',
      to: '/s/partners/',
    },
    {
      text: 'Investor Relations',
      target: '_blank',
      to: 'https://investors.grove.co/',
    },
    {
      text: 'Recycling',
      target: '_blank',
      to: 'https://community.grove.co/hc/en-us/articles/360052887091-How-can-I-recycle-my-used-and-empty-products',
    },
    {
      text: 'Blog',
      to: 'https://homeplanet.grove.co/',
    },
    {
      text: 'Guides & Tips',
      to: '/blog/',
    },
    ROUTES.contactUs,
  ],
  grove: [
    {
      text: 'Our Mission',
      to: '/g/our-mission',
    },
    {
      text: 'Beyond Plastic',
      to: '/beyondplastic/',
    },
    {
      text: 'Environmental Impact',
      to: '/g/sustainability/',
    },
    ROUTES.groveStandard,
    {
      text: 'Free Returns',
      to: '/g/shipping-returns/',
    },
  ],
  social: [
    {
      text: 'Instagram',
      to: 'https://www.instagram.com/grovecollaborative/',
      target: '_blank',
      image:
        'https://images.grove.co/upload/v1715962970/global/Icons/Social/Instagram.svg',
    },
    {
      text: 'Facebook',
      to: 'https://www.facebook.com/GroveCollab/',
      target: '_blank',
      image:
        'https://images.grove.co/upload/v1715962970/global/Icons/Social/Facebook.svg',
    },
    {
      text: 'TikTok',
      to: 'https://www.tiktok.com/@grovecollaborative/',
      target: '_blank',
      image:
        'https://images.grove.co/upload/v1715962970/global/Icons/Social/TikTok.svg',
    },
    {
      text: 'LinkedIn',
      to: 'https://www.linkedin.com/company/grove-collaborative/',
      target: '_blank',
      image:
        'https://images.grove.co/upload/v1715962971/global/Icons/Social/LinkedIn.svg',
    },
    {
      text: 'YouTube',
      to: 'https://www.youtube.com/channel/UCymHP9mlJIV5yfcuT70e_5w/featured',
      target: '_blank',
      image:
        'https://images.grove.co/upload/v1715962971/global/Icons/Social/YouTube.svg',
    },
  ],
  policy: [
    ROUTES.carbonDisclosures,
    ROUTES.californiaSupplyChain,
    ROUTES.privacy,
    ROUTES.terms,
    ROUTES.policies,
    {
      text: 'Accessibility Policy',
      to: '/policies/#accessibility-policy',
    },
  ],
}
